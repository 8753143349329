
import Feature from '../../components/Feature'
import Quote from '../../components/Quote'
import VimeoEmbed from '../../../../components/VimeoEmbed'
import CallToAction from './CallToAction'

import { ReactComponent as ShopIcon } from '../../../../assets/icons/shop.svg'
import { ReactComponent as PriceTagIcon } from '../../../../assets/icons/price-tag.svg'
import { ReactComponent as TruckIcon } from '../../../../assets/icons/truck.svg'

export default function MarketingHomeConcierge() {
  return (
    <div id="concierge" className="flex flex-col container max-w-5xl mx-auto my-8 md:my-32 px-4">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex flex-col container max-w-xl mx-auto mb-8 md:mb-16">
          <h2 className="mb-4 text-2xl md:text-3xl font-bold">
            Keep staff focused on patients, not purchasing
          </h2>

          <p className="leading-normal text-neutral-700">
            With Details Concierge you get a Purchasing Concierge to handle all the hassles of ordering from multiple suppliers, and a single platform to order through. This frees staff up to focus on patients and more important projects in the office.
          </p>
        </div>
      </div>

      <div className="flex mb-16">
        <VimeoEmbed id='715589540' title='Purchasing doesnt have to suck video' />
      </div>

      <div className="flex flex-col md:flex-row gap-8 mb-16">
        <Feature
          heading="Keep your suppliers"
          content="Your Concierge will work with all of your current suppliers to take the hassles of day-to-day purchasing off your staff’s hands."
          icon={<ShopIcon />}
          className="basis-1/3"
        />

        <Feature
          heading="Get the best price"
          content="Your Concierge spends their day uncovering the very best prices suppliers have so they can ensure you’re getting the best possible deal."
          icon={<PriceTagIcon />}
          className="basis-1/3"
        />

        <Feature
          heading="Say goodbye to ordering headaches"
          content="Problems with orders are inevitable. Your Concierge pro-actively works with suppliers to avoid issues like back-orders, sudden pricing changes, and incorrect orders."
          icon={<TruckIcon />}
          className="basis-1/3"
        />
      </div>

      <Quote author='Purchasing Staff Member'>
        I have <em>so</em> much more time to work with patients and on other stuff around the office. I feel like I’ve got my life back!
      </Quote>

      <CallToAction />
    </div>
  )
}
export default function Customer(props) {
  const { customer, ...rest } = props

  return (
    <figure {...rest}>
      <img
        className='mb-2 rounded-md overflow-hidden [transform:translateZ(0)] w-full'
        src={customer.image}
        alt={customer.altText}
      />
      <figcaption className='text-sm text-neutral-500'>{customer.caption}</figcaption>
    </figure>
  )
}